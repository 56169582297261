import rzrturboRUltimate from '../assets/vehicles/rzr/turbo-r-ultimate.jpg'
import rzrturboR4Ultimate from '../assets/vehicles/rzr/turbo-r-4-ultimate.jpg'
import rzrProRUltimate from '../assets/vehicles/rzr/pro-r-ultimate.jpg'
import rzrProR4Ultimate from '../assets/vehicles/rzr/pro-r-4-ultimate.jpg'
import rzrTrailS1000Premium from '../assets/vehicles/rzr/trail-s-1000-premium.jpg'
import rzrXp4Sport from '../assets/vehicles/rzr/xp-4-sport.jpg'
import rzrXp4Ultimate from '../assets/vehicles/rzr/xp-4-ultimate.jpg'
import rzrXpPremium from '../assets/vehicles/rzr/xp-premium.jpg'
import rzrXpUltimate from '../assets/vehicles/rzr/xp-ultimate.jpg'
import rzr200Efi from '../assets/vehicles/rzr/200-efi.jpg'
import rangerSp570 from '../assets/vehicles/ranger/sp-570.jpg'
import rangerSp570Premium from '../assets/vehicles/ranger/sp-570-premium.jpg'
import rangerCrewSp570 from '../assets/vehicles/ranger/crew-sp-570.jpg'
import rangerCrewSp570Premium from '../assets/vehicles/ranger/crew-sp-570-premium.jpg'
import ranger150Efi from '../assets/vehicles/ranger/150-efi.jpg'
import sportsman570 from '../assets/vehicles/sportsman/570.jpg'
import sportsmanTouring570 from '../assets/vehicles/sportsman/touring-570.jpg'
import generalXp41000Premium from '../assets/vehicles/general/xp-4-1000-premium.jpg'
import generalXp41000Ultimate from '../assets/vehicles/general/xp-4-1000-ultimate.jpg'
import xpeditionXp5Ultimate from '../assets/vehicles/xpedition/xp-5-ultimate.jpg'
import xpeditionXp5Northstar from '../assets/vehicles/xpedition/xp-5-northstar.jpg'
import xpeditionAdv5Northstar from '../assets/vehicles/xpedition/adv-5-northstar.jpg'
import rzrLogo from '../assets/logos/rzr-logo.svg'
import generalLogo from '../assets/logos/general-logo.svg'
import sportsmanLogo from '../assets/logos/sportsman-logo.svg'
import rangerLogo from '../assets/logos/ranger-logo.svg'
import xpeditionLogo from '../assets/logos/xpedition-logo.svg'
import polarisLogo from '../assets/logos/logo-polaris-black.svg'

type VehiclesList = {
    group: string;
    logo: string;
    models: {
        name: string,
        price: number,
        image?: string,
        characteristics?: {
            description: string,
            features: string[],
            specifications: {
                name: string,
                items: string[][]
            }[]
        }
    }[]
}

const vehiclesList : VehiclesList[] = [
    {
        group: "RZR",
        logo: rzrLogo,
        models: [
            {
                name: "RZR TURBO R ULTIMATE",
                price: 240990,
                image: rzrturboRUltimate,
                characteristics: {
                    description: 'Equipado com a suspensão mais inteligente da indústria — DYNAMIX DV e Amortecedores com Bypass Interno etronicamente ajustáveis FOX 3.0 Live Valve X2 — o Turbo R Ultimate está pronto para qualquer terreno.',
                    features: [
                        'Dynamix DV com 4 Modos Selecionáveis',
                        'Amortecedores FOX 3.0 Live Valve X2 com Bypass Interno',
                        'Direção Assistida Eletrônica de Alta Assistência Montada em Caixa de Direção Rápida'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote), Prostar Turbo H.O'],
                                ['Cilindradas', '925 cc / 184 CV'],
                                ['Sistema de combustível', 'Injeção Eletronica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda Isolado performance extrema'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão dianteira', 'Braço duplo "Light Weight Boxed" (tipo A-Arm) com barra estabilizadora de 3 componentes, 68,6 cm de curso de suspensão utilizável e 56,5 cm de curso. Cubos de roda unificados.'],
                                ['Amortecedor traseira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão traseira', 'Balança tubular com reforços e "Toe Link", Braços de suspensão standard com barra estabilizadora, 71,9 cm de curso de suspensão utilizável e 56,9 cm de curso. Cubos de roda unificados.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de três pistões na dianteira e dois pistões na traseira, discos esportivos com rotor em alumínio'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Pneus traseiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Rodas', 'Alumínio com pintura e 5 pontos de fixação']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '66 x 45 x 29.2 cm'],
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '45.4 L'],
                                ['Altura livre do solo', '40.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '325 x 188 x 189 cm'],
                                ['Capacidade de carga útil', '336 kg'],
                                ['Distância entre eixos', '244 cm'],
                                ['Peso (kg)', '913 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Lock & Ride'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Direção Assistida Eletrônica de Alta Assistência Montada em caixa de direção rápida; Volante esportivo com ajuste de inclinação e telescópico, Botões de controle do Ride Command e Dynamix'],
                                ['Reboque', 'Não equipado'],
                                ['Instrumentação', ' touch com luva: Instrumentação digital, GPS, Mapeamento, Group Ride, Bluetooth e USB, conectividade com celular, AM/FM, comunicação interna disponível (via dispositivo bluetooth no capacete), Som Rockfort Fosgate (com 2 Tweeters, 2 graves e amplificador de 400W), Velocimentro e tacômetro analógicos c/ centro de informações LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, saída de energia DC., Retificador de alta potência de 900w, sistema Polaris Pulse, porta de entrada para carregador de baterias no painel, 2 portas 12 V DC, Polaris P.A.S.S., Teto de alumínio pintado, Barra de suspensão traseira "Toe Link" pintada, protetor de lama na balança.'],
                                ['Iluminação', '2 Led diurnos, faróis de LED branco (baixo / alto) e lanterna e freio LED e LED lateral de alta visibilidade'],
                                ['Tipo de assento', 'Assentos esportivos com acabamento em 2 cores com 4 posições de ajuste (incluindo assento com ajuste de profundidade 152cm e ajuste de inclinação) e cintos de 6 pontos Sub-Zero retráteis'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR TURBO R 4 ULTIMATE",
                price: 269990,
                image: rzrturboR4Ultimate,              
                characteristics: {
                    description: 'Equipado com a suspensão mais inteligente da indústria — DYNAMIX DV e Amortecedores com Bypass Interno etronicamente ajustáveis FOX 3.0 Live Valve X2, tecnologia RIDE COMMAND e sistema de som premium Rockford Fosgate. — o Turbo R Ultimate está pronto para qualquer terreno.',
                    features: [
                        'Motor Turbo de 184 CV',
                        'Largura de 74" e 71,9cm de Curso de Suspensão Utilizável',
                        'Tela de 7" touch com luva com tecnologia Ride Command',
                        'Sistema de Som Premium Rockford Fosgate',
                        'Iluminação de realce frontal, traseira e interna em LED',
                        'Dynamix DV com 4 Modos Selecionáveis',
                        'Amortecedores FOX 3.0 Live Valve X2 com Bypass Interno',
                        'Direção Assistida Eletrônica de Alta Assistência Montada em Caixa de Direção Rápida'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote), Prostar Turbo H.O'],
                                ['Cilindradas', '925 cc / 184 CV'],
                                ['Sistema de combustível', 'Injeção Eletronica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda Isolado performance extrema'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão dianteira', 'Braço duplo "Light Weight Boxed" (tipo A-Arm) com barra estabilizadora de 3 componentes, 68,6 cm de curso de suspensão utilizável e 56,5 cm de curso. Cubos de roda unificados.'],
                                ['Amortecedor traseira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão traseira', 'Balança tubular com reforços e "Toe Link", Braços de suspensão standard com barra estabilizadora, 71,9 cm de curso de suspensão utilizável e 56,9 cm de curso. Cubos de roda unificados.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de três pistões na dianteira e dois pistões na traseira, discos esportivos com rotor em alumínio'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Pneus traseiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Rodas', 'Alumínio com pintura e 5 pontos de fixação']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '66 x 45 x 29.2 cm'],
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '49.2L'],
                                ['Altura livre do solo', '40.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '398,7 x 188 x 196 cm'],
                                ['Capacidade de carga útil', '408.2 kg'],
                                ['Distância entre eixos', '317.5 cm'],
                                ['Peso (kg)', '1037 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Lock & Ride, Rebatimento dos bancos traseiros com aumento de espaço livre para carga com pontos de amarração integrados, 70.4 x 134.1 x 49.53 cm'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Direção Assistida Eletrônica de Alta Assistência Montada em caixa de direção rápida; Volante esportivo com ajuste de inclinação e telescópico, Botões de controle do Ride Command e Dynamix'],
                                ['Reboque', 'Não equipado'],
                                ['Instrumentação', 'Tela 7" com Ride Command  touch com luva: Instrumentação digital, GPS, Mapeamento, Group Ride, Bluetooth e USB, conectividade com celular, AM/FM, comunicação interna disponível (via dispositivo bluetooth no capacete), Som Rockfort Fosgate (com 2 Tweeters, 2 graves e amplificador de 400W), Velocimentro e tacômetro analógicos c/ centro de informações LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, saída de energia DC., Retificador de alta potência de 900w, sistema Polaris Pulse, porta de entrada para carregador de baterias no painel, 2 portas 12 V DC, Polaris P.A.S.S., Teto de alumínio pintado, Barra de suspensão traseira "Toe Link" pintada, protetor de lama na balança'],
                                ['Iluminação', '2 Led diurnos, faróis de LED branco (baixo / alto) e lanterna e freio LED e LED lateral de alta visibilidade'],
                                ['Tipo de assento', 'Assentos esportivos com 4 posições de ajuste (incluindo assento com ajuste de profundidade 152cm e ajuste de inclinação) e cintos de 6 pontos Sub-Zero retráteis. Assentos esportivos traseiros com montagem elevada e cintos de 6 pontos'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR PRO R ULTIMATE",
                price: 334990,
                image: rzrProRUltimate,              
                characteristics: {
                    description: 'Obtenha o side-by-side mais avançado e de alto desempenho da indústria no acabamento Ultimate, carregado com a tecnologia RIDE COMMAND, sistema de som premium Rockford Fosgate  e DYNAMIX DV - o sistema de suspensão ativa mais avançado da indústria para fornecer controle máximo sobre qualquer terreno.',
                    features: [
                        'Suspensão DYNAMIX DV com 4 modos de condução',
                        'Tecnologia RIDE COMMAND integrada em display de 7" e câmera traseira',
                        'Sistema de som premium Rockford Fosgate Stage 2',
                        'Amortecedores FOX Live Valve X2',
                        'Volante com ajuste de altura e profundidade com botões de controle',
                        'Teto de alumínio',
                        'Cinto retrátil de 6 pontos',
                        'Portas completas',
                        '73,7cm de curso de suspensão utilizável',
                        'Suspensão MaxLink',
                        'Pneus de 32" e rodas de alumínio de 5 furos'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, 4 cilindros em linha, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '1997 cc / 228 CV'],
                                ['Sistema de combustível', 'Injeção Eletronica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/4WD Blocado/2WD sob demanda Isolado performance extrema'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão dianteira', 'Braço duplo "Light Weight Boxed" (tipo A-Arm) com barra estabilizadora de 3 componentes, 69 cm de curso de suspensão utilizável e 56,5 cm de curso. Cubos de roda unificados.'],
                                ['Amortecedor traseira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão traseira', 'Balança tubular com reforços e "Toe Link", Braços de suspensão standard com barra estabilizadora, 73,7 cm de curso de suspensão utilizável e 62,2 cm de curso. Cubos de roda unificados.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de três pistões na dianteira e dois pistões na traseira, discos esportivos com rotor em alumínio'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Pneus traseiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Rodas', 'Alumínio com pintura e 5 pontos de fixação']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '73.5 x 21 x 20 cm'],
                                ['Capacidade de carga da caçamba', '136.1 kg'],
                                ['Capacidade de Combustível', '45.43L'],
                                ['Altura livre do solo', '40.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '347 x 188 x 185 cm'],
                                ['Capacidade de carga útil', '335.6 kg'],
                                ['Distância entre eixos', '265.4 cm'],
                                ['Peso (kg)', '992 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Lock & Ride, Protetor de caçamba instalado de fábrica'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Direção Assistida Eletrônica de Alta Assistência Montada em caixa de direção rápida; Volante esportivo com ajuste de inclinação e telescópico, Botões de controle do Ride Command e Dynamix'],
                                ['Reboque', 'Não equipado'],
                                ['Instrumentação', 'Tela 7" com Ride Command'],
                                ['Iluminação', '2 Led diurnos, faróis de LED branco (baixo / alto) e lanterna e freio LED e LED lateral de alta visibilidade'],
                                ['Tipo de assento', 'Assentos esportivos com acabamento em 2 cores com 4 posições de ajuste (incluindo assento com ajuste de profundidade 152cm e ajuste de inclinação) e cintos de 6 pontos retráteis.'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR PRO R 4 ULTIMATE",
                price: 384990,
                image: rzrProR4Ultimate,              
                characteristics: {
                    description: 'Obtenha o side-by-side mais avançado e de alto desempenho da indústria no acabamento Ultimate, carregado com a tecnologia RIDE COMMAND, sistema de som premium Rockford Fosgate  e DYNAMIX DV - o sistema de suspensão ativa mais avançado da indústria para fornecer controle máximo sobre qualquer terreno.',
                    features: [
                        'Suspensão DYNAMIX DV com 4 modos de condução',
                        'Tecnologia RIDE COMMAND integrada em display de 7" e câmera traseira',
                        'Sistema de som premium Rockford Fosgate Stage 2',
                        'Amortecedores FOX Live Valve X2',
                        'Volante com ajuste de altura e profundidade com botões de controle',
                        'Teto de alumínio',
                        'Cinto retrátil de 6 pontos',
                        'Portas completas',
                        '73,7cm de curso de suspensão utilizável',
                        'Suspensão MaxLink',
                        'Pneus de 32" e rodas de alumínio de 5 furos'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, 4 cilindros em linha, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '1997 cc / 228 CV'],
                                ['Sistema de combustível', 'Injeção Eletronica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/4WD Blocado/2WD sob demanda Isolado performance extrema'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão dianteira', 'Braço duplo "Light Weight Boxed" (tipo A-Arm) com barra estabilizadora de 3 componentes, 69 cm de curso de suspensão utilizável e 56,5 cm de curso. Cubos de roda unificados.'],
                                ['Amortecedor traseira', 'Amortecedores FOX 3.0 Live Valve X2 com bypass interno (Controle eletrônico de compressão e retorno)'],
                                ['Suspensão traseira', 'Balança tubular com reforços e "Toe Link", Braços de suspensão standard com barra estabilizadora, 73,7 cm de curso de suspensão utilizável e 62,2 cm de curso. Cubos de roda unificados.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de três pistões na dianteira e dois pistões na traseira, discos esportivos com rotor em alumínio'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Pneus traseiros', 'Pneus 32 x 10-15 Maxxis Rampage Fury; 8-lonas; Rodas 15x7'],
                                ['Rodas', 'Alumínio com pintura e 5 pontos de fixação']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '75 x 60.5 x 20.6 cm'],
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '46.56L'],
                                ['Altura livre do solo', '40.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '420 x 188 x 194 cm'],
                                ['Capacidade de carga útil', '408 kg'],
                                ['Capacidade de passageiros', '4'],
                                ['Distância entre eixos', '339.1 cm'],
                                ['Peso (kg)', '1,124.91 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Lock & Ride, Rebatimento dos bancos traseiros com aumento de espaço livre para carga com pontos de amarração integrados (70.4 x 134.1 x 49.53 cm)'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Direção elétrica assistida de alta assistência aontada em caixa de direção rápida; volante esportivo com ajuste de inclinação e telescópico, botões de controle do Ride Command e Dynamix DV'],
                                ['Reboque', 'Não equipado'],
                                ['Instrumentação', 'Tela de 7" com Ride Command touch com luva: Instrumentação digital, GPS, Mapeamento, Group Ride, Bluetooth e USB, conectividade com celular, AM/FM, comunicação interna disponivel (via dispositivo bluetooth no capacete), Som Rockfort Fosgate (com 2 Tweeters, 2 graves e amplificador de 400W), Velocímentro e tacômetro analogicos c/ centro de informações LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, saída de energia DC. Alternador com 1700w de potência, sistema Polaris Pulse, porta de entrada para carregador de baterias no painel, 2 portas 12 V DC, Polaris P.A.S.S., teto de Aluminio pintado, barra de suspensão traseira "Toe Link" pintada, scoop dianteiro, protetor de lama na balança, 3 modos de condução de motor (Sport, Rock e Race)'],
                                ['Iluminação', 'Led diurno central, faróis de LED branco (baixo / alto) e lanterna e freio LED e LED lateral de alta visibilidade'],
                                ['Tipo de assento', 'Assentos esportivos com acabamento em 2 cores com 4 posições de ajuste (incluindo assento com ajuste de profundidade e ajuste de inclinação) e cintos de 6 pontos Sub-Zero retráteis. Assentos esportivos traseiros com montagem elevada e cintos de 6 pontos'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR TRAIL S 1000 PREMIUM",
                price: 112990,
                image: rzrTrailS1000Premium,
                characteristics: {
                    description: 'Capacidade líder de trilha em uma máquina de 60" de largura. O acabamento Premium apresenta faróis em LED, rodas de alumínio e direção elétrica.',
                    features: [
                        'Direção elétrica (EPS)',
                        'Luzes diurnas centrais e faróis em LED',
                        'Tração AWD de alta performance',
                        'Amortecedores Fox 2.0 Podium',
                        'Portas completas',
                        'Pneus de 27"',
                        'Rodas de alumínio',
                        'Receptor de engate padrão de 1,25"',
                        'Sistema Polaris Pulse'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'Prostar, 4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '999 cc / 101 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'FOX 2.0 PODIUM X com corpo de 2" e ajuste de compressão'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com curso de 31,1 cm com barra estabilizadora'],
                                ['Amortecedor traseira', 'FOX 2.0 PODIUM X com corpo de 2" e ajuste de compressão'],
                                ['Suspensão traseira', 'Braço duplo (tipo A) com curso de 33,5 cm com barra estabilizadora'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nas quatro rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '27 x 9-12; Trailmaster A/T'],
                                ['Pneus traseiros', '27 x 11-R12; Trailmaster A/T'],
                                ['Rodas', 'Alumínio com pintura na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '35.9 L'],
                                ['Altura livre do solo', '31.8 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '282 x 152.4 x 176 cm'],
                                ['Capacidade de carga útil', '336 kg'],
                                ['Distância entre eixos', '200.7 cm'],
                                ['Peso (kg)', '600 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema Lock & Ride: travas que facilitam a instalação ou remoção de carga'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Equipado'],
                                ['Dispositivo de Reboque', '680.4 kg'],
                                ['Reboque', 'Receptor padrão de 3,2 cm'],
                                ['Instrumentação', 'Velocimentro e tacômetro analogicos c/ centro de informações LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, saída de energia DC. Volante esportivo com regulagem em altura, caixa de direção rapida com 2 voltas de ponta a ponta, assentos com novo desenho e ajuste de profundidade para o condutor, gaiola de proteção reforçada com extensão traseira até o chassi. Novo painel com mais espaço para guardar objetos.'],
                                ['Iluminação', 'Faróis com mascara negra de LED branco (baixo/alto), LED central dianteiro e LED vermelho no freio, central e presença na traseira'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR XP PREMIUM",
                price: 139990,
                image: rzrXpPremium,
                characteristics: {
                    description: 'Ano/modelo 2024',
                    features: [
                        'Som Rockford Fosgate Stage 1',
                        '30 x 9-14; Trail Master X/T 2.0',
                        'Teto Poliesportivo',
                        'Pintura e Gráfismo Premium',
                        'Motor ProStar 1000 Gen 2 de 116cv',
                        'Amortecedores Walker Evans Racing',
                        'Pára-choques dianteiro, placa antiderrapante completa, portas completas',
                        'Barra de conexão elétrica para acessório de 6 posições',
                        'Faróis de LED'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'ProStar 1000 H.O., 4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '999 CC'],
                                ['Potência', '116 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores Walker Evans 2" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com curso utilizável de 52,7 cm e curso de roda de 40,6 cm com barra estabilizadora'],
                                ['Amortecedor traseira', 'Amortecedores Walker Evans 2.5" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão traseira', 'Independente, braço triplo transversal (3-Link Trailing Arm) com curso utilizável de 52,7 cm e curso de roda de 45,7 cm com barra estabilizadora.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de dois pistões nas 4 rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '30 x 10-14; Trail Master X/T 2.0'],
                                ['Pneus traseiros', '30 x 10-14; Trail Master X/T 2.0'],
                                ['Rodas', 'Alumínio com pintura na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '72 x 57 x 17.8 cm'],
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '36 L'],
                                ['Altura livre do solo', '36.8 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '304.8 x 162.6 x 188 cm'],
                                ['Capacidade de carga útil', '336 kg'],
                                ['Distância entre eixos', '228.6 cm'],
                                ['Peso (kg)', '742.5 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema Lock & Ride: travas que facilitam a instalação ou remoção de carga'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Equipado Original com Caixa de direção rápida'],
                                ['Dispositivo de Reboque', '680.4 kg'],
                                ['Reboque', 'Receptor padrão de 3,2 cm'],
                                ['Instrumentação', 'Velocimentro e tacômetro analogicos c/ centro de informações LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, saída de energia DC. Sistema de som Rockford Fosgate PMX 2 com sintonizador AM/FM, receptor de midia digital compacto, tela colorida de 2,7" TFT, 2 caixas de som dianteiras.'],
                                ['Iluminação', 'Faróis LED branco (baixo / alto), LED vermelho no freio e presença na traseira.'],
                                ['Outros Recursos Padrão', 'Portas completas, Assentos com detalhes de cor, Detalhe de cor no painel, Alternador com 660W, Barra de conexão de acessórios com 6 posições, Volante com regulagem em altura, Teto esportivo policarbonato, Cintos de 4 pontas Subzero ajustáveis, cinta de reboque dianteira e para-choques dianteiro.'],
                                ['Tipo de assento', 'Bancos concha reforçados com ajuste para o motorista, cinto de segurança de 4 pontas'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR XP ULTIMATE",
                price: 154990,
                image: rzrXpUltimate,
                characteristics: {
                    description: 'Ano/modelo 2024',
                    features: [
                        'Tela 7" com Ride Command + câmera traseira',
                        'Som Rockford Fosgate Stage 3',
                        '30 x 9-14; Trail Master X/T 2.0',
                        'Teto Poliesportivo',
                        'Cintos de segurança de 4 pontos',
                        'Iluminação frontal e traseira de realce em LED',
                        'Pintura e Gráfismo Premium',
                        'Motor ProStar 1000 Gen 2 de 116cv',
                        'Amortecedores Walker Evans Racing',
                        'Pára-choques dianteiro, placa antiderrapante completa, portas completas',
                        'Barra de conexão elétrica para acessório de 6 posições',
                        'Faróis de LED'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'ProStar 1000 H.O., 4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '999 CC'],
                                ['Potência', '116 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores Walker Evans 2" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com curso utilizável de 52,7 cm e curso de roda de 40,6 cm com barra estabilizadora'],
                                ['Amortecedor traseira', 'Amortecedores Walker Evans 2.5" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão traseira', 'Independente, braço triplo transversal (3-Link Trailing Arm) com curso utilizável de 52,7 cm e curso de roda de 45,7 cm com barra estabilizadora.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de dois pistões nas 4 rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '30 x 10-14; Trail Master X/T 2.0'],
                                ['Pneus traseiros', '30 x 10-14; Trail Master X/T 2.0'],
                                ['Rodas', 'Alumínio com pintura na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '72 x 57 x 17.8 cm'],
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '36 L'],
                                ['Altura livre do solo', '36.8 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '304.8 x 162.6 x 188 cm'],
                                ['Capacidade de carga útil', '336 kg'],
                                ['Distância entre eixos', '228.6 cm'],
                                ['Peso (kg)', '744.8 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema Lock & Ride: travas que facilitam a instalação ou remoção de carga'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Equipado Original com Caixa de direção rápida'],
                                ['Dispositivo de Reboque', '680.4 kg'],
                                ['Reboque', 'Receptor padrão de 3,2 cm'],
                                ['Instrumentação', 'Tela 7" com Ride Command  touch com luva: Instrumentação digital, GPS, Mapeamento, Bluetooth e USB, conectividade com celular, AM/FM, comunicação interna disponivel (via dispositivo bluetooth no capacete), velocímetro, odômetro, tacômetro, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura, indicador de cinto de segurança, bateria. Som Rockfort Fosgate Stage 3 (Inclui 2 caixas na dianteira e 2 na traseira com amplificador de 400W).'],
                                ['Iluminação', 'Faróis LED branco (baixo / alto), LED central dianteiro e LED vermelho no freio, central e presença na traseira.'],
                                ['Outros Recursos Padrão', 'Portas completas, Assentos com detalhes de cor, Detalhe de cor no painel e para-choques dianteiro, Alternador com 900W, Barra de conexão de acessórios com 6 posições, Volante com regulagem em altura, Teto esportivo policarbonato, Camêra traseira, Cintos de 4 pontas Subzero ajustáveis, cinta de reboque dianteira.'],
                                ['Tipo de assento', 'Bancos concha reforçados com ajuste para o motorista, cinto de segurança de 4 pontas'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR XP 4 SPORT",
                price: 149990,
                image: rzrXp4Sport,
                characteristics: {
                    description: '',
                    features: [
                        '29 x 9-14; Trail Master X/T',
                        'Cintos de segurança de 3 pontos',
                        'Centro de informações LCD de 4" na cor azul e/ou vermelha',
                        'Motor ProStar 1000 Gen 2 de 116cv',
                        'Amortecedores Walker Evans Racing',
                        'Pára-choques dianteiro, placa antiderrapante completa, portas completas',
                        'Barra de conexão elétrica para acessório de 6 posições',
                        'Faróis de LED',
                        'Assento traseiro elevado'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'ProStar 1000 H.O., 4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '999 CC'],
                                ['Potência', '116 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores Walker Evans 2" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com curso utilizável de 50,8 cm e curso de roda de 40,6 cm com barra estabilizadora'],
                                ['Amortecedor traseira', 'Amortecedores Walker Evans 2.5" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão traseira', 'Independente, braço triplo transversal (3-Link Trailing Arm) com curso utilizável de 52,7 cm e curso de roda de 45,7 cm com barra estabilizadora.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de dois pistões nas 4 rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '29 x 9-14; Trail Master X/T'],
                                ['Pneus traseiros', '29 x 11-14; Trail Master X/T'],
                                ['Rodas', 'Alumínio com pintura na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '72 x 57 x 17.8 cm'],
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '36 L'],
                                ['Altura livre do solo', '35.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '373.1 x 162.6 x 184.9 cm'],
                                ['Capacidade de carga útil', '408 kg'],
                                ['Distância entre eixos', '297.2 cm'],
                                ['Peso (kg)', '821.9 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema Lock & Ride: travas que facilitam a instalação ou remoção de carga'],
                                ['Portas', 'Portas completas dianteiras e traseiras'],
                                ['Direção Assistida Eletrônica', 'Equipado Original com Caixa de direção rápida'],
                                ['Reboque', 'Não Equipado'],
                                ['Instrumentação', 'Velocimentro e tacômetro analogicos c/ centro de informações LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, saída de energia DC.'],
                                ['Iluminação', 'Faróis LED branco (baixo / alto), LED vermelho no freio e presença na traseira.'],
                                ['Outros Recursos Padrão', 'Portas completas, Assentos com detalhes de cor, Alternador com 660W, Barra de conexão de acessórios com 6 posições, Volante com regulagem em altura, Cintos de 3 pontas retrateis, cinta de reboque dianteira e parachoques dianteiro.'],
                                ['Tipo de assento', 'Bancos concha reforçados com ajuste para o motorista, cintos de segurança de 3 pontas'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RZR XP 4 ULTIMATE",
                price: 169990,
                image: rzrXp4Ultimate,
                characteristics: {
                    description: '',
                    features: [
                        'Tela 7" com Ride Command + câmera traseira',
                        'Som Rockford Fosgate Stage 3',
                        '30 x 9-14; Trail Master X/T 2.0',
                        'Teto Poliesportivo',
                        'Cintos de segurança de 4 pontos',
                        'Iluminação frontal e traseira de realce em LED',
                        'Pintura e Gráfismo Premium',
                        'Motor ProStar 1000 Gen 2 de 116cv',
                        'Amortecedores Walker Evans Racing',
                        'Pára-choques dianteiro, placa antiderrapante completa, portas completas',
                        'Faróis de LED',
                        'Assento traseiro elevado'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'ProStar 1000 H.O., 4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '999 CC'],
                                ['Potência', '116 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores Walker Evans 2" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com curso utilizável de 52,7 cm e curso de roda de 40,6 cm com barra estabilizadora'],
                                ['Amortecedor traseira', 'Amortecedores Walker Evans 2.5" de agulha com ajuste de compressão (16 clicks)'],
                                ['Suspensão traseira', 'Independente, braço triplo transversal (3-Link Trailing Arm) com curso utilizável de 52,7 cm e curso de roda de 45,7 cm com barra estabilizadora.'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas com pinças de dois pistões nas 4 rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '30 x 10-14; Trail Master X/T 2.0'],
                                ['Pneus traseiros', '30 x 10-14; Trail Master X/T 2.0'],
                                ['Rodas', 'Alumínio com pintura na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '72 x 57 x 17.8 cm'],
                                ['Capacidade de carga da caçamba', '136 kg'],
                                ['Capacidade de Combustível', '36 L'],
                                ['Altura livre do solo', '36.8 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '375.4 x 162.6 x 190.5 cm'],
                                ['Capacidade de carga útil', '408 kg'],
                                ['Distância entre eixos', '297.2 cm'],
                                ['Peso (kg)', '862.3 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema Lock & Ride: travas que facilitam a instalação ou remoção de carga'],
                                ['Portas', 'Portas completas dianteiras e traseiras'],
                                ['Direção Assistida Eletrônica', 'Equipado Original com Caixa de direção rápida'],
                                ['Reboque', 'Não Equipado'],
                                ['Instrumentação', 'Tela 7" com Ride Command  touch com luva: Instrumentação digital, GPS, Mapeamento, Bluetooth e USB, conectividade com celular, AM/FM, comunicação interna disponivel (via dispositivo bluetooth no capacete), velocímetro, odômetro, tacômetro, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura, indicador de cinto de segurança, bateria. Som Rockfort Fosgate Stage 3 (Inclui 2 caixas na dianteira e 2 na traseira com amplificador de 400W).'],
                                ['Iluminação', 'Faróis LED branco (baixo / alto), LED central dianteiro e LED vermelho no freio, central e presença na traseira.'],
                                ['Outros Recursos Padrão', 'Portas completas, Assentos com detalhes de cor, Detalhe de cor no painel e para-choques dianteiro, Alternador com 900W, Barra de conexão de acessórios com 6 posições, Volante com regulagem em altura, Teto esportivo policarbonato, Camêra traseira, Cintos de 4 pontas Subzero ajustáveis, cinta de reboque dianteira.'],
                                ['Tipo de assento', 'Bancos concha reforçados com ajuste para o motorista, cintos de segurança de 3 pontas'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
                    
        ]
    },
    {
        group: "Ranger",
        logo: rangerLogo,
        models: [
            {
                name: "RANGER SP 570",
                price: 89990,
                image: rangerSp570,
                characteristics: {
                    description: 'O RANGER SP 570 é perfeito para o trabalho, simples de dirigir e tem tamanho ideal.',
                    features: [
                        'Motor ProStar 570 com 44CV',
                        'Capacidade de reboque de 680kg',
                        'Caçamba basculante com capacidade de 226,8kg',
                        '27,9cm de distância do solo',
                        'Raio de Giro de 3,8m',
                        'Parabarros totalmente novos'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, monocilíndrico DOHC'],
                                ['Cilindradas', '567 cc / 44 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Suspensão MacPherson com curso de 9" (22,9 cm)'],
                                ['Raio de giro', '3.8 m'],
                                ['Suspensão traseira', 'Independente, braço duplo (tipo A-Arm) com curso de 10" (25,4 cm) e barra estabilizadora'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nos discos dianteiros e traseiros'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '25 x 8-12; Carlisle 489'],
                                ['Pneus traseiros', '25 x 10-12; Carlisle 489'],
                                ['Rodas', 'Aço Estampado']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '84,5 x 123,7 x 31,2 cm'],
                                ['Capacidade de carga da caçamba', '226.8 kg'],
                                ['Capacidade de Combustível', '35.9 L'],
                                ['Altura livre do solo', '27,9 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '274,3 x 142,2 x 188 cm'],
                                ['Capacidade de carga útil', '453.6 kg'],
                                ['Distância entre eixos', '185.4 cm'],
                                ['Capacidade de passageiros', '2'],
                                ['Peso (kg)', '525 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema Lock & Ride'],
                                ['Direção Assistida Eletrônica', 'Não equipado'],
                                ['Dispositivo de Reboque', '680.4 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Painel LCD de 4" com: Luz de fundo e brilho azul / vermelho selecionáveis ​​pelo usuário, Intervalos de serviço programáveis, Velocímetro, Tacômetro, Odômetro, Odômetro parcial, Relógio, Horímetro, Indicador de marcha, Medidor de combustível, Temperatura do material, Voltímetro, Indicador de serviço e Códigos, Lembrete de cinto, tomada DC. Caçamba basculante com assistência a gás, tampa traseira de trava única, sistema de escape isolado de fibra de vidro montado na parte traseira, tomada para carregador de bateria no painel, sistema de carregamento de 660 W (estator), 75 litros de armazenamento disponível sob o assento, suporte para celular integrado no painel, buchas de suspensão seladas e rolamentos de roda, para-choque dianteiro de aço com montagem de guincho disponível.'],
                                ['Iluminação', 'Farol dianteiro de 55W e lanterna de LED na traseira'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RANGER SP 570 PREMIUM",
                price: 99990,
                image: rangerSp570Premium,
                characteristics: {
                    description: 'Todos os ótimos recursos do RANGER SP 570, além de direção elétrica assistida, faróis de LED, para-choque de cobertura total e muito mais. ',
                    features: [
                        'Motor ProStar 570 com 44CV',
                        'Capacidade de reboque de 680kg',
                        'Caçamba basculante com capacidade de 226,8kg',
                        '27,9cm de distância do solo',
                        'Faróis de LED',
                        'Curso de suspensão de 22,9cm na dianteira e 25,4cm na traseira',
                        'Raio de Giro de 3,8m',
                        'Direção Elétrica (EPS)',
                        'Placa de proteção inferior completa',
                        'Para-choque dianteiro com proteção completa',
                        'Parabarros totalmente novos'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, monocilíndrico DOHC'],
                                ['Cilindradas', '567 cc / 44 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Suspensão MacPherson com curso de 9" (22,9 cm)'],
                                ['Raio de giro', '3.8 m'],
                                ['Suspensão traseira', 'Independente, braço duplo (tipo A-Arm) com curso de 10" (25,4 cm) e barra estabilizadora'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nos discos dianteiros e traseiros'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '25 x 8-12; Carlisle 489'],
                                ['Pneus traseiros', '25 x 10-12; Carlisle 489'],
                                ['Rodas', 'Aro 12" em Alumínio na cor preto']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '84,5 x 123,7 x 31,2 cm'],
                                ['Capacidade de carga da caçamba', '226.8 kg'],
                                ['Capacidade de Combustível', '35.9 L'],
                                ['Altura livre do solo', '27,9 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '274,3 x 142,2 x 188 cm'],
                                ['Capacidade de carga útil', '420 kg'],
                                ['Distância entre eixos', '185.4 cm'],
                                ['Capacidade de passageiros', '2'],
                                ['Peso (kg)', '565 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema Lock & Ride'],
                                ['Direção Assistida Eletrônica', 'Equipado'],
                                ['Dispositivo de Reboque', '680.4 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Painel LCD de 4" com: Luz de fundo e brilho azul / vermelho selecionáveis ​​pelo usuário, Intervalos de serviço programáveis, Velocímetro, Tacômetro, Odômetro, Odômetro parcial, Relógio, Horímetro, Indicador de marcha, Medidor de combustível, Temperatura do material, Voltímetro, Indicador de serviço e Códigos, Lembrete de cinto, tomada DC. Caçamba basculante com assistência a gás, tampa traseira de trava única, sistema de escape isolado de fibra de vidro montado na parte traseira, tomada para carregador de bateria no painel, sistema de carregamento de 660 W (estator), 75 litros de armazenamento disponível sob o assento, suporte para celular integrado no painel, buchas de suspensão seladas e rolamentos de roda, para-choque dianteiro completo de aço com montagem de guincho disponível, protetor de açoalho completo, acabamento personalizado nos assentos com detalhes no encosto, sistema Polaris Pulse, porta de carregamento USB, paineis traseiros premium'],
                                ['Iluminação', 'Farol dianteiro de 55W e lanterna de LED na traseira'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "RANGER CREW SP 570",
                price: 99990,
                image: rangerCrewSp570,
                characteristics: {
                    description: 'O RANGER CREW SP 570 é perfeito para o trabalho, simples de dirigir e tem tamanho ideal.',
                    features: [
                        'Motor ProStar 570 com 44CV',
                        'Capacidade de reboque de 680kg',
                        'Caçamba basculante com capacidade de 226,8kg',
                        '27,9cm de distância do solo',
                        'Raio de Giro de 4.9 m',
                        'Parabarros totalmente novos'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, monocilíndrico DOHC'],
                                ['Cilindradas', '567 cc / 44 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Suspensão MacPherson com curso de 9" (22,9 cm)'],
                                ['Raio de giro', '4.9 m'],
                                ['Suspensão traseira', 'Independente, braço duplo (tipo A-Arm) com curso de 10" (25,4 cm) e barra estabilizadora'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nos discos dianteiros e traseiros'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '25 x 8-12; Carlisle 489'],
                                ['Pneus traseiros', '25 x 10-12; Carlisle 489'],
                                ['Rodas', 'Aço Estampado']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '84,5 x 123,7 x 31,2 cm'],
                                ['Capacidade de carga da caçamba', '226.8 kg'],
                                ['Capacidade de Combustível', '35.9 L'],
                                ['Altura livre do solo', '26,7 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '365,8 x 142,2 x 188 cm'],
                                ['Capacidade de carga útil', '555,6 kg'],
                                ['Distância entre eixos', '267,1 cm'],
                                ['Capacidade de passageiros', '4'],
                                ['Peso (kg)', '640 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Direção Assistida Eletrônica', 'Não equipado'],
                                ['Dispositivo de Reboque', '680.4 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Painel LCD de 4" com: Luz de fundo e brilho azul / vermelho selecionáveis ​​pelo usuário, Intervalos de serviço programáveis, Velocímetro, Tacômetro, Odômetro, Odômetro parcial, Relógio, Horímetro, Indicador de marcha, Medidor de combustível, Temperatura do material, Voltímetro, Indicador de serviço e Códigos, Lembrete de cinto, tomada DC. Caçamba basculante com assistência a gás, tampa traseira de trava única, sistema de escape isolado de fibra de vidro montado na parte traseira, tomada para carregador de bateria no painel, sistema de carregamento de 660 W (estator), 75 litros de armazenamento disponível sob o assento, suporte para celular integrado no painel, buchas de suspensão seladas e rolamentos de roda, para-choque dianteiro de aço com montagem de guincho disponível.'],
                                ['Iluminação', 'Farol dianteiro de 55W e lanterna de LED na traseira'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                },                
            },
            {
                name: "RANGER CREW SP 570 PREMIUM",
                price: 112990,
                image: rangerCrewSp570Premium,
                characteristics: {
                    description: 'Todos os ótimos recursos do RANGER CREW SP 570, além de direção elétrica assistida, faróis de LED, para-choque de cobertura total e muito mais.',
                    features: [
                        'Motor ProStar 570 com 44CV',
                        'Capacidade de reboque de 680kg',
                        'Caçamba basculante com capacidade de 226,8kg',
                        '27,9cm de distância do solo',
                        'Faróis de LED',
                        'Curso de suspensão de 22,9cm na dianteira e 25,4cm na traseira',
                        'Raio de Giro de 4,9m',
                        'Direção Elétrica (EPS)',
                        'Placa de proteção inferior completa',
                        'Para-choque dianteiro com proteção completa',
                        'Parabarros totalmente novos'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 tempos, monocilíndrico DOHC'],
                                ['Cilindradas', '567 cc / 44 CV'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda e módulo Versatrac Turf (desbloqueio de diferencial)'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Suspensão MacPherson com curso de 9" (22,9 cm)'],
                                ['Raio de giro', '4.9 m'],
                                ['Suspensão traseira', 'Independente, braço duplo (tipo A-Arm) com curso de 10" (25,4 cm) e barra estabilizadora'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nos discos dianteiros e traseiros'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '25 x 8-12; Carlisle 489'],
                                ['Pneus traseiros', '25 x 10-12; Carlisle 489'],
                                ['Rodas', '12" em alumínio na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '84,5 x 123,7 x 31,2 cm'],
                                ['Capacidade de carga da caçamba', '226.8 kg'],
                                ['Capacidade de Combustível', '35.9 L'],
                                ['Altura livre do solo', '26,7 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '365,8 x 142,2 x 188 cm'],
                                ['Capacidade de carga útil', '499 kg'],
                                ['Distância entre eixos', '267,1 cm'],
                                ['Capacidade de passageiros', '4'],
                                ['Peso (kg)', '703 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Direção Assistida Eletrônica', 'Equipado'],
                                ['Dispositivo de Reboque', '680.4 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Painel LCD de 4" com: Luz de fundo e brilho azul / vermelho selecionáveis ​​pelo usuário, Intervalos de serviço programáveis, Velocímetro, Tacômetro, Odômetro, Odômetro parcial, Relógio, Horímetro, Indicador de marcha, Medidor de combustível, Temperatura do material, Voltímetro, Indicador de serviço e Códigos, Lembrete de cinto, tomada DC. Caçamba basculante com assistência a gás, tampa traseira de trava única, sistema de escape isolado de fibra de vidro montado na parte traseira, tomada para carregador de bateria no painel, sistema de carregamento de 660 W (estator), 75 litros de armazenamento disponível sob o assento, suporte para celular integrado no painel, buchas de suspensão seladas e rolamentos de roda, para-choque dianteiro completo de aço com montagem de guincho disponível, protetor de açoalho completo, acabamento personalizado nos assentos com detalhes no encosto, sistema Polaris Pulse, porta de carregamento USB, paineis traseiros premium'],
                                ['Iluminação', 'Farol dianteiro de 55W e lanterna de LED na traseira'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            
        ]
    },
    {
        group: "Sportsman",
        logo: sportsmanLogo,
        models: [
            {
                name: "SPORTSMAN 570",
                price: 59990,
                image: sportsman570,
                characteristics: {
                    description: 'O ATV 4x4 automático mais vendido do mundo, atualizado para ser a máquina mais suave, mais forte e mais versátil do mercado.',
                    features: [
                        'Tração AWD sob demanda',
                        'Braço de suspensão duplo tipo A',
                        '24,1cm de curso de suspensão traseira',
                        'Pneus de 25" com rodas de aço de 12"',
                        'Receptor de engate instalado de fábrica',
                        'Capacidade de reboque de 612 kg',
                        'Capacidade de carga útil de 220 kg',
                        'Capacidade combinada de rack dianteiro e traseiro de 122 kg',
                        'Pré-cabeado para adição de acessórios',
                        'Suporte de montagem de guincho instalado de fábrica'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'Prostar, monocilíndrico, DOHC (Duplo Comando de Válvulas no Cabeçote)'],
                                ['Cilindradas', '567 cc / 44 CV'],
                                ['Sistema de combustível', 'EFI (Injeção Eletrônica)'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'AWD/2WD sob demanda One Touch'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Suspensão MacPherson com curso de 20,8 cm'],
                                ['Suspensão traseira', 'Independente, braço duplo (tipo A-Arm) com curso de 24,1 cm'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nos discos dianteiros e traseiros'],
                                ['Freio de estacionamento', 'Posição P no câmbio automático / Trava de freio no manete'],
                                ['Pneus dianteiros', '25 x 8-12; 6 lonas'],
                                ['Pneus traseiros', '25 x 10-12; 6 lonas'],
                                ['Rodas', '12" de aço estampado']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '40.8 kg/81.6 kg'],
                                ['Capacidade de Combustível', '17 L'],
                                ['Altura livre do solo', '29 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '210.8 x 121.9 x 119.4 cm'],
                                ['Capacidade de carga útil', '220 kg'],
                                ['Distância entre eixos', '128.3 cm'],
                                ['Peso (kg)', '318 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Altura do assento', '84,5 cm'],
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Direção Assistida Eletrônica', 'Não equipado'],
                                ['Dispositivo de Reboque', '612 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 3,17 cm'],
                                ['Instrumentação', 'Painel total digital com: velocímetro, odômetro, tacômetro, odômetro parcial 1, odômetro parcial 2, Horimetro, indicador de marcha, combustível, AWD, voltimetro e temperatura do motor, luz indicadora de temperatura. Extensor de rack frontal, plugue de alimentação de 12 volts, pré-cabeado para aquecedores de mão e polegar, plugue p/ carregador de bateria no painel, suporte de guincho incluído.'],
                                ['Iluminação', 'Farois dianteiros no para-choque e guidão, sendo duplo de 50W (baixo) no para-choque e triplo de 50 W (alto), lanterna traseira de LED unica com luz de freio.'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "SPORTSMAN TOURING 570",
                price: 75990,
                image: sportsmanTouring570,
                characteristics: {
                    description: 'Condução e manuseio lendário que você exige, junto a um sistema integrado de assento para passageiro para versatilidade de dois lugares e direção elétrica.',
                    features: [
                        'Sistema integrado de assento do passageiro',
                        'Real AWD/2WD sob demanda',
                        'Direção elétrica assistida (EPS)',
                        'Capacidade de reboque de 555,7 kg',
                        'Capacidade de carga do bagageiro dianteiro de 41 kg e 82 kg no traseiro',
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 Tempos, monocilíndrico, DOHC (Duplo Comando de Válvulas no Cabeçote)'],
                                ['Cilindradas', '567 cc / 44 CV'],
                                ['Sistema de combustível', 'EFI (Injeção Eletrônica)'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Suspensão MacPherson com curso de 8,2" (20,8 cm)'],
                                ['Suspensão traseira', 'Independente, braço duplo (tipo A-Arm) com curso de 24,1 cm'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nos discos dianteiros e traseiros'],
                                ['Freio de estacionamento', 'Posição P no câmbio automático / Trava de freio no manete'],
                                ['Pneus dianteiros', '25 x 8-12; 489'],
                                ['Pneus traseiros', '25 x 10-12; 489'],
                                ['Rodas', 'Aço estampado']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', 'N/A'],
                                ['Capacidade de carga da caçamba', '41 kg / 82 kg'],
                                ['Capacidade de Combustível', '25.5 L'],
                                ['Altura livre do solo', '27.9 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '218.4 x 121.9 x 121.9 cm'],
                                ['Capacidade de carga útil', '220 kg'],
                                ['Distância entre eixos', '112.2 cm'],
                                ['Peso (kg)', '336 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Altura do assento', '85,7 cm'],
                                ['Acessórios e Armazenamento', 'Lock & Ride, almacenamiento en seco delantero de 23 l (6 gal), almacenamiento trasero de 8 l (2 gal)'],
                                ['Direção Assistida Eletrônica', 'Equipado'],
                                ['Dispositivo de Reboque', '555,7 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 3,2 cm'],
                                ['Instrumentação', 'Painel digital, velocímetro analógico, odômetro, tacômetro, odômetro parcial indicador de marcha, combustível e AWD. Luzes indicadoras de temperatura e bateria, saída de energia DC'],
                                ['Iluminação', 'Farol dianteiro duplo de 50W (baixo) no para-choque, farol dianteiro no guidão (alto), lanterna traseira e luz de freio'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            }
        ]
    },
    {
        group: "General",
        logo: generalLogo,
        models: [
            {
                name: "GENERAL XP 4 1000 PREMIUM",
                price: 169990,
                image: generalXp41000Premium,
                characteristics: {
                    description: 'Eleve sua experiência off-road com a versão Premium, com sistema de som Rockford Fosgate Stage 1.',
                    features: [
                        'Sistema de som Rockford Fosgate Stage 1 com unidade principal PMX',
                        'Espelho retrovisor convexo',
                        'Apoio de braço central acolchoado',
                        'Caçamba basculante com capacidade de 272kg',
                        'Curso de Suspensão de 35,6cm',
                        'Braços de suspensão duplo tipo A',
                        'Pneus Pro Armor Crawler XG de 30" ou ITP Ultra Cross R Spec',
                        'Guincho Polaris HD Plus 4500 lb.',
                        'Teto esportivo de policarbonato',
                        'Para-choque esportivo',
                        'Real AWD com Versatrac Turf Mode',
                        'Meias portas premium'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'Prostar 1000, 4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '101 CV / 999 cc'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda e módulo Versatrac Turf (desbloqueio de diferencial)'],
                                ['Sistema de freio motor', 'Equipado original'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Walker Evans Velocity Series com ajuste de compressão'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) arqueado com grande curso de 35,6cm e barra estabilizadora'],
                                ['Amortecedor traseira', 'Walker Evans Velocity Series com ajuste de compressão'],
                                ['Suspensão traseira', 'Braço duplo (tipo A) arqueado com grande curso de 35,6cm e barra estabilizadora'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nas quatro rodas'],
                                ['Freio de estacionamento', 'Posição P no câmbio automático / Trava de freio no manete'],
                                ['Pneus dianteiros', '30 x 10-15; PRO ARMOR CRAWLER XGF OU ITP ULTRA CROSS R SPEC'],
                                ['Pneus traseiros', '30 x 10-15; PRO ARMOR CRAWLER XGF OU ITP ULTRA CROSS R SPEC'],
                                ['Rodas', '15" de alumínio na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '69.8 x 109 x 29.5 cm'],
                                ['Capacidade de carga da caçamba', '272 kg'],
                                ['Capacidade de Combustível', '36 L'],
                                ['Altura livre do solo', '34.3 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '381,5 x 162,6 x 195,5 cm'],
                                ['Capacidade de carga útil', '580 kg'],
                                ['Capacidade de passageiros', '4'],
                                ['Distância entre eixos', '292 cm '],
                                ['Peso (kg)', '890 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Equipado'],
                                ['Dispositivo de Reboque', '680 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Velocímentro e tacômetro analógicos com centro de informações em LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, 2 pontos de saída de energia DC. Guincho POLARIS HD 4500 LB, Para-choque dianteiro, Teto ProFit Sport, Sistema de som Rockford Fosgate Stage 1'],
                                ['Iluminação', 'Faróis de LED com mascara negra e LED vermelho no freio e presença na traseira'],
                                ['Tipo de assento', 'Bancos esportivos reforçados com ajuste de profundidade do banco do motorista e cintos de segurança com ajuste de altura'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "GENERAL XP 4 1000 ULTIMATE",
                price: 179990,
                image: generalXp41000Ultimate,
                characteristics: {
                    description: 'Tenha o nível máximo de experiência com a versão Ultimate, com todas os recursos da versão Premium além da tecnologia exclusiva Ride Command e sistema de som Rockford Fosgate Stage 3.',
                    features: [
                        'Tecnologia RIDE COMMAND integrada a tela de 7" e câmera traseira',
                        'Sistema de som Rockford Fosgate Stage 3',
                        'Espelho retrovisor convexo',
                        'Apoio de braço central acolchoado',
                        'Caçamba basculante com capacidade de 272kg',
                        'Curso de Suspensão de 35,6cm',
                        'Braços de suspensão duplo tipo A',
                        'Pneus Pro Armor Crawler XG de 30" ou ITP Ultra Cross R Spec',
                        'Guincho Polaris HD Plus 4500 lb.',
                        'Teto esportivo de policarbonato',
                        'Para-choque esportivo',
                        'Real AWD com Versatrac Turf Mode',
                        'Meias portas premium'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'Prostar 1000, 4 tempos, bicilíndrico, DOHC (Duplo comando de válvulas no cabeçote)'],
                                ['Cilindradas', '101 CV / 999 cc'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda e módulo Versatrac Turf (desbloqueio de diferencial)'],
                                ['Sistema de freio motor', 'Equipado original'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Walker Evans Velocity Series com ajuste de compressão'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) arqueado com grande curso de 35,6cm e barra estabilizadora'],
                                ['Amortecedor traseira', 'Walker Evans Velocity Series com ajuste de compressão'],
                                ['Suspensão traseira', 'Braço duplo (tipo A) arqueado com grande curso de 35,6cm e barra estabilizadora'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nas quatro rodas'],
                                ['Freio de estacionamento', 'Posição P no câmbio automático / Trava de freio no manete'],
                                ['Pneus dianteiros', '30 x 10-15; PRO ARMOR CRAWLER XGF OU ITP ULTRA CROSS R SPEC'],
                                ['Pneus traseiros', '30 x 10-15; PRO ARMOR CRAWLER XGF OU ITP ULTRA CROSS R SPEC'],
                                ['Rodas', '15" de alumínio na cor preta']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '69.8 x 109 x 29.5 cm'],
                                ['Capacidade de carga da caçamba', '272 kg'],
                                ['Capacidade de Combustível', '36 L'],
                                ['Altura livre do solo', '34.3 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '381,5 x 162,6 x 195,5 cm'],
                                ['Capacidade de carga útil', '580 kg'],
                                ['Capacidade de passageiros', '4'],
                                ['Distância entre eixos', '292 cm '],
                                ['Peso (kg)', '890 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Portas', 'Portas completas'],
                                ['Direção Assistida Eletrônica', 'Equipado'],
                                ['Dispositivo de Reboque', '680 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Velocímentro e tacômetro analógicos com centro de informações em LCD de 4" na cor azul e/ou vermelho selecionáveis pelo usuário, regulagem de brilho; intervalos de serviço programáveis; odômetro, medidor de tempo, odômetro parcial, horímetro, voltímetro, relógio, indicador de marcha, combustível, temperatura e bateria, 2 pontos de saída de energia DC. Guincho POLARIS HD 4500 LB, Para-choque dianteiro, Teto ProFit Sport, Sistema de som Rockford Fosgate Stage 1'],
                                ['Iluminação', 'Faróis de LED com mascara negra e LED vermelho no freio e presença na traseira'],
                                ['Tipo de assento', 'Bancos esportivos reforçados com ajuste de profundidade do banco do motorista e cintos de segurança com ajuste de altura'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            }
        ]
    },
    {
        group: "Xpedition",
        logo: xpeditionLogo,
        models: [
            {
                name: "POLARIS XPEDITION XP 5 ULTIMATE",
                price: 239990,
                image: xpeditionXp5Ultimate,
                characteristics: {
                    description: 'Prepare-se para sua próxima aventura que levará voce, sua turma e todo o equipamento que você puder colocar na grande caçamba.',
                    features: [
                        'Motor ProStar 1000 Gen 2 de 114 HP',
                        'Tecnologia RIDE COMMAND integrada em um display de toque de 7"',
                        'Áudio JBL® Trail Pro 4100',
                        'Câmeras frontal e traseira',
                        'Direção inclinada e telescópica',
                        'Sistema de carregamento do estator de 900 W',
                        'Guincho Inclui: Modo Autostop e Plow'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 Tempos, Bicilíndrico, DOHC (Duplo Comando de Válvulas no Cabeçote)'],
                                ['Cilindradas', '999 cc'],
                                ['Potência', '116 cv'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores FOX 2.0 Podium QS3 com tecnologia de espiral sensível à posição'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com barra estabilizadora e curso utilizável de 35,6cm'],
                                ['Amortecedor traseira', 'Amortecedores FOX 2.5 Podium QS3 com tecnologia de espiral sensível à posição'],
                                ['Suspensão traseira', 'Braço Duplo, com curso utilizável de 38.1 cm'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nas quatro rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '30 x 10-15; Pro Armor Crawler XP'],
                                ['Pneus traseiros', '30 x 11-15; Pro Armor Crawler XP'],
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '77 x 120 x 34 cm'],
                                ['Capacidade de carga da caçamba', '272 kg'],
                                ['Capacidade de Combustível', '47.3 L'],
                                ['Altura livre do solo', '35.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '387.3 x 162.5 x 190.2 cm'],
                                ['Capacidade de carga útil', '526 kg'],
                                ['Capacidade de passageiros', '5'],
                                ['Distância entre eixos', '297.1 cm'],
                                ['Peso (kg)', '890 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Sistema de áudio', 'JBL Trail Pro 4100'],
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Direção Assistida Eletrônica', 'Equipado'],
                                ['Dispositivo de Reboque', '907 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Driver Forward Information Center com visor colorido de 4" + velocímetro e tacômetros giratórios e painel central com tela sensível ao toque de 7" com tecnologia Ride Command'],
                                ['Iluminação', 'Farol de LED com realce e lanternas traseiras de LED'],
                                ['Outros Recursos Padrão', 'Guincho POLARIS HD 4500 LB com corda, batente de borracha e cabo guia de alumínio, AutoStop e modo Plow, pára-choque dianteiro, teto esportivo ProFit, espelho retrovisor convexo, áudio JBL Trail Pro 4100'],
                                ['Tipo de assento', 'Frente: Bancos concha reforçados com controle deslizante do banco do motorista e do passageiro. Traseiro: Assento rebatível 60/40 reforçado'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "POLARIS XPEDITION XP 5 NORTHSTAR",
                price: 279990,
                image: xpeditionXp5Northstar,
                characteristics: {
                    description: 'Prepare-se para sua próxima aventura que levará voce, sua turma e todo o equipamento que você puder colocar na grande caçamba.',
                    features: [
                        'Motor ProStar 1000 Gen 2 de 114 HP',
                        'Ar Condicionado com Controle de Temperatura ',
                        'Portas completas trancáveis ​​com vidros elétricos',
                        'Tecnologia RIDE COMMAND+ integrada em um display de 7"',
                        'Câmeras frontal e traseira',
                        'Direção inclinada',
                        'Som JBL® Trail Pro 4100',
                        'Guincho Inclui: Modo Autostop e Plow',
                        'Sistema de carregamento do estator de 140 A'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 Tempos, Bicilíndrico, DOHC (Duplo Comando de Válvulas no Cabeçote)'],
                                ['Cilindradas', '999 cc'],
                                ['Potência', '116 cv'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores FOX 2.0 Podium QS3 com tecnologia de espiral sensível à posição'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com barra estabilizadora e curso utilizável de 35,6cm'],
                                ['Amortecedor traseira', 'Amortecedores FOX 2.5 Podium QS3 com tecnologia de espiral sensível à posição'],
                                ['Suspensão traseira', 'Braço Duplo, com curso utilizável de 38.1 cm'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nas quatro rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '30 x 10-15; Pro Armor Crawler XP'],
                                ['Pneus traseiros', '30 x 11-15; Pro Armor Crawler XP'],
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '77 x 120 x 34 cm'],
                                ['Capacidade de carga da caçamba', '272 kg'],
                                ['Capacidade de Combustível', '47.3 L'],
                                ['Altura livre do solo', '35.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '387.3 x 162.5 x 190.2 cm'],
                                ['Capacidade de carga útil', '526 kg'],
                                ['Capacidade de passageiros', '5'],
                                ['Distância entre eixos', '297.1 cm'],
                                ['Peso (kg)', '1243 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Sistema de áudio', 'JBL Trail Pro 4100'],
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Direção Assistida Eletrônica', 'Equipado Original com Caixa de direção rápida'],
                                ['Dispositivo de Reboque', '907 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Driver Forward Information Center com visor colorido de 4" + velocímetro e tacômetros giratórios e painel central com tela sensível ao toque de 7" com tecnologia Ride Command'],
                                ['Iluminação', 'Farol de LED com realce e lanternas traseiras de LED'],
                                ['Outros Recursos Padrão', 'Guincho POLARIS HD 4500 LB com corda, batente de borracha e cabo guia de alumínio, AutoStop e modo Plow, pára-choque dianteiro, teto esportivo ProFit, espelho retrovisor convexo, áudio JBL Trail Pro 4100'],
                                ['Tipo de assento', 'Bancos concha reforçados com controle deslizante do banco do motorista'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
            {
                name: "POLARIS XPEDITION ADV 5 NORTHSTAR",
                price: 299990,
                image: xpeditionAdv5Northstar,
                characteristics: {
                    description: 'Carregue seu equipamento, afaste-se da multidão e comece sua próxima aventura com uma cabine totalmente coberta e um porta malas para levar você, sua equipe e todo equipamento para as aventuras mais extremas.',
                    features: [
                        'Motor ProStar 1000 Gen 2 de 114 HP',
                        'Ar Condicionado com Controle de Temperatura ',
                        'Portas completas trancáveis ​​com vidros elétricos',
                        'Pára-brisa basculante com lavador e limpador e escotilha traseira',
                        'Câmeras frontal e traseira',
                        'Direção inclinada',
                        'Som JBL® Trail Pro 4100',
                        'Guincho Polaris HD 4500 LB',
                        'Sistema de carregamento do estator de 140 A'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', '4 Tempos, Bicilíndrico, DOHC (Duplo Comando de Válvulas no Cabeçote)'],
                                ['Cilindradas', '999 cc'],
                                ['Potência', '116 cv'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'PVT Automático - P / R / N / L / H'],
                                ['Sistema de tração', 'Real AWD/2WD sob demanda de alta performance'],
                                ['Refrigeração', 'Liquida']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Amortecedor dianteira', 'Amortecedores FOX 2.0 Podium QS3 com tecnologia de espiral sensível à posição'],
                                ['Suspensão dianteira', 'Braço duplo (tipo A) com barra estabilizadora e curso utilizável de 35,6cm'],
                                ['Amortecedor traseira', 'Amortecedores FOX 2.5 Podium QS3 com tecnologia de espiral sensível à posição'],
                                ['Suspensão traseira', 'Braço Duplo, com curso utilizável de 38.1 cm'],
                                ['Freios dianteiros/traseiros', 'Disco hidráulico nas 4 rodas, pinça de dois pistões nas quatro rodas'],
                                ['Freio de estacionamento', 'Posição P na transmissão automática'],
                                ['Pneus dianteiros', '30 x 10-15; Pro Armor Crawler XP'],
                                ['Pneus traseiros', '30 x 11-15; Pro Armor Crawler XP'],
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '92 x 120 x 34 cm'],
                                ['Capacidade de carga da caçamba', '272 kg'],
                                ['Capacidade de Combustível', '47.3 L'],
                                ['Altura livre do solo', '35.6 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '387.3 x 162.5 x 190.2 cm'],
                                ['Capacidade de carga útil', '526 kg'],
                                ['Capacidade de passageiros', '5'],
                                ['Distância entre eixos', '297.1 cm'],
                                ['Peso (kg)', '1265 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Sistema de áudio', 'JBL Trail Pro 4100'],
                                ['Acessórios e Armazenamento', 'Sistema "Lock & Ride": travas que facilitam a instalação ou remoção de carga'],
                                ['Direção Assistida Eletrônica', 'Equipado Original com Caixa de direção rápida'],
                                ['Dispositivo de Reboque', '907 kg'],
                                ['Reboque', 'Receptor de entrada do reboque de 5,08 cm'],
                                ['Instrumentação', 'Driver Forward Information Center com visor colorido de 4" + velocímetro e tacômetros giratórios e painel central com tela sensível ao toque de 7" com tecnologia Ride Command'],
                                ['Iluminação', 'Farol de LED com realce e lanternas traseiras de LED'],
                                ['Outros Recursos Padrão', 'Guincho POLARIS HD 4500 LB com corda, batente de borracha e cabo guia de alumínio, AutoStop e modo Plow, pára-choque dianteiro, teto esportivo ProFit, espelho retrovisor convexo, áudio JBL Trail Pro 4100'],
                                ['Tipo de assento', 'Bancos concha reforçados com controle deslizante do banco do motorista'],
                                ['Homologação', 'Off-road']
                            ]
                        }
                    ]
                }
            },
        ]
    },
    {
        group: "Youth",
        logo: polarisLogo,
        models: [
            {
                name: "RZR 200 EFI",
                price: 67990,
                image: rzr200Efi,
                characteristics: {
                    description: 'Desempenho adequado para crianças que cresce com eles e todos os recursos de segurança de que os pais precisam.',
                    features: [
                        'Tecnologia de controle de direção',
                        'Tecnologia de reconhecimento de capacete',
                        'Portas rígidas',
                        'Faróis e lanternas traseiras de LED',
                        '25.4cm de distância ao solo',
                        'Braços frontais duplos',
                        'Pneus 24"'
                    ],
                    specifications: [
                        {
                            name: 'Motor e sistema de transmissão',
                            items: [
                                ['Motor', 'Monocilíndrico 4 tempos'],
                                ['Cilindradas', '180cc'],
                                ['Sistema de combustível', 'Injeção Eletronica'],
                                ['Transmissão', 'Corrente; Automatico PVT F/N/R'],
                                ['Sistema de tração', '2WD'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Ar e Óleo']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Braço A duplo com curso de 17.8 cm'],
                                ['Suspensão traseira', 'Suspensão traseira independente com curso de 17.8cm'],
                                ['Freios dianteiros/traseiros', 'Disco Hidráulico nas 4 Rodas'],
                                ['Freio de estacionamento', 'Manual - Acionado'],
                                ['Pneus dianteiros', '24 x 8 - 12 CST'],
                                ['Pneus traseiros', '24 x 10 - 12 CST'],
                                ['Rodas', 'Aço Estampado']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '41.9 x 48.3 x 10.1 cm'],
                                ['Capacidade de Combustível', '45.4 L'],
                                ['Altura livre do solo', '25.4 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '225.6 x 121.9 x 151.2 cm'],
                                ['Capacidade de carga útil', '170 kg'],
                                ['Capacidade de passageiros', '2'],
                                ['Distância entre eixos', '165.1 cm'],
                                ['Peso (kg)', '334 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Instrumentação', 'Visor digital com indicador de marcha, odômetro, medidor de viagem, tensão, temperatura do motor, intervalo de horas do motor, velocidade de solo, rotação do motor, comando de passeio para jovens (limitação de velocidade, geofencing, reconhecimento de capacete)'],
                                ['Iluminação', 'Luzes de corrida LED, luz de freio traseira única/luz traseira'],
                                ['Outros recursos padrão', 'Cinto de segurança, bandeira de segurança, redes de segurança, gaiola de proteção completa, DVD de treinamento, controle de passeio para jovens (inicialização do código PIN, limitador de velocidade ajustável, geofencing, reconhecimento de capacete).'],
                            ]
                        }
                    ]
                }
            },
            {
                name: "RANGER 150 EFI",
                price: 59990,
                image: ranger150Efi,
                characteristics: {
                    description: 'O RANGER 150 EFI oferece diversão com potência que eles podem crescer e recursos de segurança líderes do setor que os pais desejam. Para maiores de 10 anos com supervisão de um adulto.',
                    features: [
                        'Tecnologia de reconhecimento de capacete totalmente nova',
                        'Youth RIDE CONTROL Habilitando geo-fencing, limitação de velocidade e rastreamento de localização',
                        'Início seguro protegido por senha Polaris',
                        'Sistema de Intertravamento do Cinto de Segurança',
                        'Injeção Eletrônica de Combustível',
                        'Direção de inclinação e assento ajustável',
                        '20.3cm de Distância ao Solo'
                    ],
                    specifications: [
                        {
                            name: 'Monocilíndrico 4 tempos',
                            items: [
                                ['Motor', '4 tempos, monocilíndrico DOHC'],
                                ['Cilindradas', '150 cc'],
                                ['Sistema de combustível', 'Injeção Eletrônica'],
                                ['Transmissão', 'Corrente; Automatico PVT F/N/R'],
                                ['Sistema de tração', '2WD'],
                                ['Sistema de freio motor', 'Não equipado'],
                                ['Refrigeração', 'Ar e Óleo']
                            ]
                        },
                        {
                            name: 'Suspensão / Freios / Pneus',
                            items: [
                                ['Suspensão dianteira', 'Braço A único com curso de 13 cm'],
                                ['Suspensão traseira', 'Suspensão traseira independente com curso de 15.2cm'],
                                ['Freios dianteiros/traseiros', 'Disco Hidráulico nas 4 Rodas'],
                                ['Freio de estacionamento', 'Manual - Acionado'],
                                ['Pneus dianteiros', '22 x 7-10 Wanda'],
                                ['Pneus traseiros', '22 x 10-10 Wanda'],
                                ['Rodas', 'Aço Estampado']
                            ]
                        },
                        {
                            name: 'Dimensões',
                            items: [
                                ['Dimensões da caçamba (CxLxA)', '53.3 x 106.7 x 12.7 cm'],
                                ['Capacidade de carga da caçamba', '22.7 kg'],
                                ['Capacidade de Combustível', '9.5 L'],
                                ['Altura livre do solo', '20.3 cm'],
                                ['Dimensões gerais do veículo (CxLxA)', '215.9 x 121.9 x 147.3 cm'],
                                ['Capacidade de carga útil', '170 kg'],
                                ['Distância entre eixos', '165.1 cm'],
                                ['Capacidade de passageiros', '2'],
                                ['Peso (kg)', '305 Kg']
                            ]
                        },
                        {
                            name: 'Outras características',
                            items: [
                                ['Instrumentação', 'Visor digital com indicador de marcha, hodômetro, medidor de percurso, tensão, temperatura do motor, intervalo de horas do motor, velocidade de deslocamento, rotação do motor'],
                                ['Iluminação', 'Luzes diurnas, luz de freio/luz traseira única'],
                                ['Outros Recursos Padrão', 'Cinto de segurança, bandeira de segurança, redes de segurança, gaiola de proteção completa, DVD de treinamento, controle de passeio para jovens (inicialização do código PIN, limitador de velocidade ajustável, geofencing, reconhecimento de capacete).']
                            ]
                        }
                    ]
                }
            },
        ]
    },
]

export default vehiclesList